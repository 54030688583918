<template>
    <div class="er">
        <img class="icon" src="@/assets/imgs/error/404.png">
        <p class="page-container">没有找到你要的页面<br>你是不是迷路了?</p>
        <p class="page-container">10秒后返回登陆页</p>
    </div>
</template>

<script>
export default {
    mounted(){
        setTimeout(()=>{
            this.$router.replace('/')
        },10000)
    }
}
</script>

<style scoped>
.er {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #eeeeee;
}

.page-container {
    font-size: 1rem;
    text-align: center;
    color: #b7b7b7;
}

.router-link-active {
    font-size: 14px;
}

.icon {
    margin-top: 50px;
    margin-bottom: 30px;
}
</style>
